import {useRef, type ReactElement} from 'react';

import BaseAddItem from './BaseAddItem.js';

export default function DocumentItem(props: {
    onDocumentSubmit: (file: File) => void | Promise<void>;
}): ReactElement {
    const documentItemRef = useRef<HTMLDivElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);

    return (
        <>
            <input
                ref={fileRef}
                type="file"
                style={{display: 'none'}}
                onChange={async event => {
                    if (event === null || event.target === null || event.target.files === null) {
                        return;
                    }

                    try {
                        for (const file of event.target.files) {
                            await props.onDocumentSubmit(file);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }}
                multiple
            />
            <div ref={documentItemRef}>
                <BaseAddItem
                    type={'document'}
                    onClick={() => {
                        if (fileRef.current === null) {
                            console.error('Unable to handle onClick, due to no fileRef detected');
                            return;
                        }
                        fileRef.current.click();
                    }}
                />
            </div>
        </>
    );
}
