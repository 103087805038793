import {useCallback, useContext, useRef, useState, type ReactElement} from 'react';
import {isMobile} from 'react-device-detect';

import ImageCapturePopup from '@refinio/one.ui/lib/ui/views/imageCapture/ImageCapturePopup.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {MenuContext} from '@/context/MenuContext.js';
import BaseAddItem from './BaseAddItem.js';

export default function ImageItem(props: {
    onDocumentSubmit: (file: File) => void | Promise<void>;
}): ReactElement {
    const imageItemRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLInputElement>(null);
    const {
        setMenuEntries,
        selectMenuEntry,
        isOpen,
        setMenuReference,
        setMenuClassName,
        setMenuAnchorOrigin
    } = useContext(MenuContext);
    const [showCamera, setShowCamera] = useState<boolean>(false);

    const openMenu = useCallback(() => {
        setMenuReference(imageItemRef.current);
        setMenuAnchorOrigin({horizontal: 'center', vertical: 'center'});
        setMenuClassName('app-bar-cnt-add-menu');
        setMenuEntries([MENU_ENTRY.SELECT_IMAGES, MENU_ENTRY.TAKE_IMAGE]);
        selectMenuEntry(() => (entrySelected: string) => {
            setMenuClassName('app-bar-cnt-menu');
            isOpen(false);
            switch (entrySelected) {
                case MENU_ENTRY.SELECT_IMAGES:
                    if (imageRef.current === null) {
                        console.error(
                            `Unable to handle entrySelected '${entrySelected}', due to no imageRef detected`
                        );
                        return;
                    }
                    imageRef.current.click();
                    break;
                case MENU_ENTRY.TAKE_IMAGE:
                    setShowCamera((s: boolean) => !s);
                    break;
            }
        });
        isOpen(true);
    }, []);

    return (
        <>
            <input
                ref={imageRef}
                type="file"
                style={{display: 'none'}}
                accept="image/*"
                onChange={async event => {
                    if (event === null || event.target === null || event.target.files === null) {
                        return;
                    }

                    try {
                        for (const file of event.target.files) {
                            await props.onDocumentSubmit(file);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }}
                multiple
            />
            {showCamera && (
                <ImageCapturePopup
                    onFileChange={props.onDocumentSubmit}
                    active={showCamera}
                    onClose={setShowCamera.bind(setShowCamera, (s: boolean) => !s)}
                />
            )}
            <div ref={imageItemRef}>
                <BaseAddItem
                    type={'image'}
                    onClick={() => {
                        if (isMobile) {
                            if (imageItemRef.current === null) {
                                console.error(
                                    'Unable to handle onClick, due to no imageItemRef detected'
                                );
                                return;
                            }
                            imageItemRef.current.click();
                        } else {
                            openMenu();
                        }
                    }}
                />
            </div>
        </>
    );
}
